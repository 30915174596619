import React from 'react';

const StreamingIcon = ({ isActive = true, variant = 'light' }) => (
  <svg width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.5002 34.6002H3.4002C1.8002 34.6002 0.700195 33.5002 0.700195 31.9002V3.9002C0.700195 2.3002 1.8002 1.2002 3.4002 1.2002H42.2002C43.8002 1.2002 44.9002 2.3002 44.9002 3.9002V31.9002C45.0002 33.5002 43.8002 34.6002 42.5002 34.6002Z"
      fill={
        isActive
          ? '#E0494B'
          : (variant === 'light' && '#5C5E7B') || (variant === 'dark' && '#898B9E')
      }
    />
    <path
      d="M42.5 34.7C36.2 34.7 29.9 35 23.6 35.3C17.3 35.6 11 35.6 4.7 35.5H3.5C3.1 35.5 2.5 35.4 2.1 35.2C1.1 34.9 0.3 34 0.1 33C0 32.5 0 32 0 31.6V30.4L0.1 28.1C0.2 25 0.3 21.8 0.3 18.7C0.3 15.6 0.3 12.4 0.3 9.3C0.3 7.7 0.3 6.2 0.2 4.6V4C0.2 3.8 0.2 3.6 0.2 3.3C0.3 2.8 0.4 2.4 0.7 2C1.3 1.1 2.3 0.7 3.2 0.6C9.6 0.4 15.8 0.2 22.1 0.2C28.4 0.1 34.6 0 40.9 0H42.1C42.5 0 43.1 0.1 43.6 0.2C44.7 0.6 45.5 1.5 45.8 2.6C46 2.9 46 3.2 46 3.4C46 3.5 46 3.7 46 3.8V4.1V5.3V7.7C46 10.8 45.8 14 45.7 17.1L45.3 26.5C45.2 28.1 45.2 29.6 45.2 31.2C45.2 31.6 45.2 32 45.1 32.4C45 32.8 44.9 33.2 44.6 33.5C44.1 34.3 43.3 34.7 42.5 34.7ZM42.5 34.5C43.3 34.5 44 34.1 44.4 33.5C44.8 32.9 44.9 32.1 44.8 31.3C44.8 29.7 44.7 28.2 44.7 26.6L44.3 17.1C44.2 14 44 10.8 44 7.7V5.3V4.1V3.8V3.6C44 3.5 44 3.4 43.9 3.2C43.7 2.8 43.4 2.4 43 2.3C42.8 2.2 42.6 2.2 42.2 2.2H41C34.7 2.2 28.4 2.1 22.1 2.1C15.8 2.1 9.5 1.9 3.3 1.8C2.6 1.8 2 2.1 1.6 2.6C1.4 2.8 1.3 3.1 1.2 3.4C1.2 3.6 1.2 3.7 1.1 3.9V4.5C1.1 6.1 1.1 7.7 1.1 9.2C1.1 12.3 1 15.5 1.1 18.6C1.1 21.7 1.2 24.9 1.3 28L1.4 30.4V31.6C1.4 32 1.4 32.2 1.5 32.5C1.7 33 2.1 33.4 2.6 33.6C2.9 33.7 3.1 33.7 3.5 33.7H4.7C11 33.6 17.3 33.6 23.6 33.9C29.9 34.2 36.2 34.5 42.5 34.5Z"
      fill="#33355A"
    />
    <path d="M15.7998 9.1001V27.6001L33.3998 18.1001L15.7998 9.1001Z" fill="white" />
    <path
      d="M15.8998 9.2C16.1998 12.7 16.4998 15.8 16.5998 18.8C16.6998 20.3 16.7998 21.8 16.6998 23.2C16.6998 24.6 16.4998 26.1 16.3998 27.6L15.5998 27.1C17.0998 26.5 18.6998 25.7 20.0998 24.9C21.5998 24.1 22.9998 23.2 24.3998 22.3C25.7998 21.4 27.1998 20.5 28.5998 19.6C29.9998 18.7 31.4998 17.9 32.8998 17.1V19C31.4998 18.3 30.0998 17.6 28.6998 16.9C27.2998 16.1 25.9998 15.4 24.5998 14.5L15.8998 9.2ZM15.7998 9C18.4998 10.1 21.4998 11.3 24.5998 12.6C26.1998 13.3 27.6998 14 29.2998 14.7C30.8998 15.5 32.3998 16.3 33.8998 17.1L35.6998 18.1L33.9998 19C32.4998 19.7 30.9998 20.5 29.4998 21.2C27.9998 21.9 26.3998 22.6 24.8998 23.3C23.3998 24 21.8998 24.7 20.3998 25.5C18.8998 26.3 17.4998 27.2 16.0998 28.1L15.2998 28.6L15.1998 27.6C15.0998 26 14.8998 24.4 14.8998 22.8C14.7998 21.2 14.8998 19.5 14.9998 17.9C15.1998 14.7 15.4998 11.6 15.7998 9Z"
      fill="#33355A"
    />
  </svg>
);

export default StreamingIcon;
